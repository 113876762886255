import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const FluidImage = ({ src, contain, ...props }) => {
  const image = getImage(src?.localFile)
  return (
    <div className="flex h-full w-full transform translate-y-0" {...props}>
      <GatsbyImage
        image={image}
        alt={
          src?.title ? src.title : 'Gula Markiserna Åkersberga Frisör Klippning'
        }
        style={{ height: '100%', width: '100%' }}
        imgStyle={{ objectFit: contain ? 'contain' : 'cover' }}
      />
    </div>
  )
}

export default FluidImage
