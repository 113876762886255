import { graphql } from 'gatsby'
import React from 'react'
import Card from '../components/Card/Card'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Spacer from '../components/Spacer'

const produkter = ({ data: { page } }) => {
  const produkter = page.produkter.produkt
  return (
    <Layout>
      <Seo
        title={'Produkter'}
        description={
          'Vi erbjuder alltid de rätta professionella hårvårdsprodukterna från alla ledande märken'
        }
      />
      <Spacer />
      <div className="container">
        <div className="grid lg:grid-cols-3 gap-6">
          {produkter?.length &&
            produkter.map((item, index) => (
              <Card
                key={index}
                title={item.rubrik}
                text={item.text}
                src={item.bild}
              />
            ))}
        </div>
      </div>
      <Spacer />
    </Layout>
  )
}

export const query = graphql`
  query Produkter {
    page: wpPage(title: { eq: "Produkter" }) {
      id
      hero {
        bild {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
      produkter {
        produkt {
          rubrik
          text
          bild {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 192, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`

export default produkter
