import React from 'react'
import FluidImage from '../FluidImage/FluidImage'
import Spacer from '../Spacer'
import './Card.scss'

const Card = ({ title, src, text }) => {
  return (
    <div className="card">
      <Spacer half />
      {src && (
        <div className="card-header">
          <div className="card-header-img">
            <FluidImage src={src} />
          </div>
        </div>
      )}
      <div className="body">
        <div className="container">
          <Spacer half />
          {title && <h3 className="h7">{title}</h3>}
          <br />
          {text && <p>{text}</p>}
        </div>
      </div>
      <Spacer half />
    </div>
  )
}

export default Card
